<template>
    <div id="app">
        <div class="a_right">
            <page-header ref="header" v-if="$route.path != '/info'"></page-header>
            <div class="main-content">
                <router-view ref="main"/>
            </div>

            <page-footer></page-footer>
        </div>
        <member-info-modal :memberKey =memberKey></member-info-modal>
    </div>
</template>
<script>
    import PageHeader from '@/components/PageHeader'
    import PageFooter from '@/components/PageFooter'
    import MemberInfoModal from "@/components/modal/MemberInfoModal";
    import EventBus from "@/utils/event-bus";
    import MobileDetect from "mobile-detect";

    export default {
        name: 'App',
        mixins: [  ],
        components: {
            PageHeader,
            PageFooter,
            MemberInfoModal
        },
        data(){
            return {
                path : [],
                memberKey: 1,
            }
        },
        beforeCreate() {
            let md = new MobileDetect(window.navigator.userAgent);
            if(md.mobile() !== null){
                location.href="http://m.fatherhood.kr";
            }
        },
        created(){
            EventBus.$on('openMemberInfoModal', this.openMemberInfoModal)
        },
        beforeDestroy() {
            EventBus.$off('openMemberInfoModal')
        },
        methods: {
            openMemberInfoModal(mb_no) {
                this.memberKey = mb_no;
                this.$nextTick(() => {
                    this.$modal.show('member-info-modal')
                })
            },
        },
        watch:{

        }
    }
</script>

<style lang="scss">
    @import "./assets/scss/index.scss";
    br{
        color: transparent;
    }
</style>

